import React from "react";

const Notfound = () => {
    return (
        <>
            <p>TIDAK ADA</p>
        </>
    );
}

export default Notfound;