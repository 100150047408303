import React from "react";

const Me = () => {
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <div className="card justify-content-center align-items-center">
                            <div className="card-body">
                                CARI APA MAS? HALAMAN INI MASIH PROSES PERBAIKAN
                                <br/>
                                <a href="/">Back</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Me;